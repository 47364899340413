.services {
  padding-top: 80px;
  padding-bottom: 80px;

  .row {
    padding: 0;
  }

  .col {
    padding: 0;
    margin: 0;
    &-first {
      background-color: #2b5e5d29;
    }
    &-second {
      background-color: #fff;
    }
    &-third {
      background-color: #6c757d29;
    }
  }
  .card {
    border: none;
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 5%);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    max-width: 400px;
    font-size: 12px;

    @include largeScreen() {
      font-size: 14px;
      height: 500px;
      width: 400px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: $mobileCardBodyMarginTop;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);

      @include largeScreen() {
        height: inherit;
      }
    }
    &-outdoor {
      background-image: url(../../assets/imgs/outdoor.jpg);
    }

    &-indoor {
      background-image: url(../../assets/imgs/indoorElectric.jpg);
    }

    &-lighting {
      background-image: url(../../assets/imgs/lighting.jpg);
    }

    &-body {
      color: $mainDark;
      background: #fff;
      transition: all 0.4s ease-in-out;
      margin-top: $mobileCardBodyMarginTop;
      opacity: 0.9;
      &-reverse {
        background-color: rgb(248, 249, 250);
      }
      @include largeScreen() {
        opacity: 1;
        margin-top: unset;
        position: absolute;
        top: 60px;
        left: 80%;
        right: -90%;
        box-shadow: 0px 1px 27px -3px rgb(0 0 0 / 15%);

        &-reverse {
          left: -90%;
          right: 80%;
        }
      }
    }

    &-title {
      text-transform: capitalize;
      font-size: 2em;
      transition: all 0.4s ease-in-out;
      cursor: default;

      &:hover {
        letter-spacing: 1px;
      }
    }

    &-text {
      p {
        margin: 0;
        margin-left: 2rem;
        padding-bottom: 5px;
        position: relative;
        font-size: 1.2em;
        cursor: default;

        &::before {
          width: 10px;
          height: 10px;
          background: $mainContentColor;
          content: "";
          position: absolute;
          left: -25px;
          top: 10px;
          opacity: 1;
          transition: all 0.4s ease-in-out;
        }

        &:hover {
          &::before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

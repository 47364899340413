.contacts {
  padding: 50px 0 200px;
  &-header {
    text-align: center;
    margin-bottom: 2rem;
  }

  &-info {
    // margin-right: 2rem;
    height: 100%;
  }

  .form-control {
    margin-bottom: 15px;
    padding-left: 20px;
    border: 1px solid #efefef;
  }

  p {
    color: $mainDark;
  }

  input {
    height: 48px;
  }

  .btn {
    background-color: $mainContentColor;
    border-color: $mainContentColorDark;
    padding: 10px 60px;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: $mainContentColorDark;
    }
  }
}

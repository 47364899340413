.benefits {
  background-image: url(../../assets/imgs/benefits.jpg);
  background-color: #f1f1f1;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &-overlay {
    padding: 100px 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &-content {
    position: relative;

    &-two {
      margin-top: 1rem;
      font-size: 3rem;
      text-align: end;
    }
  }
}
